.auth-main-header {
  background-color: theme-color('primary');
  border-bottom: 10px solid theme-color('info');

  .logo {
    padding: 10px 0;
  }
}

.auth-main-footer {
  padding: 40px;
  border-top: 1px solid $gray-100;
}
