.navigation-tabs {

  .nav-tabs {
    border-bottom: 1px solid $gray-200;
  }

  .nav-item {
    .nav-link {
      color: $gray-800;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &:hover {
        border-left: 1px solid $gray-200;
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
      }

      &.active {
        font-weight: bold;
        background-color: $gray-200;
        border-left: 1px solid $gray-200;
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-400;
      }
    }
  }
}
