.main-header {
  //border-bottom: 2px solid $gray-200;
  border-bottom: 10px solid theme-color('info');
  background-color: theme-color('primary');

  .logo {
    padding: 10px;
    text-align: center;
  }

  .main-navbar {
    margin-bottom: 2px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .navbar-toggler {
      padding: 7px 9px;
      color: $navy-blue;
      background-color: $white;

      &:active {
        color: $white;
        background-color: $medium-slate-blue;
      }
    }

    .navbar-nav {
      margin-top: 10px;
      font-family: 'Lato', sans-serif;

      @include media-breakpoint-up(lg) {
        margin-top: 30px;
      }

      .nav-item {
        font-size: 15px;
        margin: 6px 0;

        @include media-breakpoint-up(lg) {
          margin: 4px;
        }

        .nav-link {
          padding: 15px;
          color: $white;
          background-color: $medium-slate-blue;
          border-bottom: 5px solid darken($navy-blue, 35%);
          border-right: 3px solid darken($navy-blue, 35%);

          @include media-breakpoint-up(lg) {
            //border-top-left-radius: 10px;
            //border-bottom-right-radius: 10px;
            border-radius: 10px;
          }

          &:hover {
            background-color: $yellow-green;
            border-bottom: 5px solid darken($yellow-green, 10%);
            border-right: 3px solid darken($yellow-green, 10%);
          }

          &:active {
            opacity: 0.9;
            transition: opacity 100ms ease-in-out;
          }
        }

        &.active {
          > .nav-link {
            background-color: $yellow-green;
            border-bottom: 5px solid darken($yellow-green, 10%);
            border-right: 3px solid darken($yellow-green, 10%);
          }
        }

        .dropdown-menu {
          margin: 0;
          padding: 0;
          border-radius: 0;
          border-left: 1px solid $gray-200;
          border-right: 1px solid $gray-200;
          border-bottom: 1px solid $gray-200;

          .title {
            color: $gray-600 !important;
            background-color: $gray-200 !important;
            border-bottom: 2px solid $gray-300;
          }

          .dropdown-item {
            font-size: 15px;
            padding: 15px;
            color: $gray-600;
            background: transparent;

            &:hover {
              color: $white;
              background-color: $yellow-green;
            }

            &:active {
              opacity: 0.9;
              transition: opacity 100ms ease-in-out;
            }
          }

          .dropdown-divider {
            margin: 0;
            border: 1px solid $gray-200;
          }
        }
      }

      // Faz com que o dropdown fique colorido no mousehover.
      .dropdown {
        &:hover {
          > .nav-link.dropdown-toggle {
            @include media-breakpoint-up(lg) {
              background-color: $yellow-green;
              border-bottom: 5px solid darken($yellow-green, 10%);
              border-right: 3px solid darken($yellow-green, 10%);
            }
          }

          // Faz com que o dropdown abra no mousehover em dispositivos large pra cima.
          @include media-breakpoint-up(lg) {
            > .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}

.welcome {
  font-size: 12px;
  color: theme-color('primary');
  background-color: $gray-100;
  font-family: 'Lato', sans-serif;
  border-bottom: 2px solid $gray-300;
}

.main-footer {
  font-size: 13px;
  background-color: $gray-100;
  border-top: 2px solid $gray-200;
  font-family: "Lato", sans-serif;
}

//@include media-breakpoint-up(xs) {}
//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}
