.box {
  border-radius: 10px;
  border: 1px solid $gray-200;
  box-shadow: 4px 4px 10px $gray-200;

  .box-title {
    padding: 25px;
    font-size: 20px;
    color: $gray-600;
    background-color: $gray-100;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid $gray-200;

    // Reduz o tamanho da caixa de título padrão.
    &.small-size {
      font-size: 18px !important;
      padding: 10px !important;
    }

  }

  .box-body {
    padding: 15px;

    .box-text {
      padding: 15px 0;

      &.featured {
        font-size: 30px;
        font-weight: bold;
      }
    }

    .info-summary {
      font-size: 13px;
    }
  }
}
