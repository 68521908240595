.block-title {
  margin: 10px;
  font-size: 20px;
  color: $gray-500;
  padding-left: 12px;
  border-bottom: 1px solid $gray-100;

  .subtitle {
    font-size: 12px;
  }
}