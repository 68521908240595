.messages {
  .default-color {
    border-left: 40px solid transparent;
  }

  .blue {
    border-left: 40px solid $dark-periwinkle;
  }

  .yellow {
    border-left: 40px solid $energy-yellow;
  }

  .green {
    border-left: 40px solid $ocean-green;
  }

  .red {
    border-left: 40px solid $monza;
  }

}
