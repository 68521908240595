.nested-list {
  .parent {
    margin-bottom: 25px;
    box-shadow: 3px 3px 4px $gray-300;
    border-left: 1px solid $gray-200;
    border-top: 1px solid $gray-200;

    > .label {
      padding: 20px;
      color: $gray-600;
      background-color: $gray-100;
      border-bottom: 1px solid $gray-200;

      &.blue {
        color: $white;
        border-bottom: 1px solid $gray-200;
        background-color: $dark-periwinkle;
      }

      &.yellow {
        color: $white;
        border-bottom: 1px solid $gray-200;
        background-color: $energy-yellow;
      }

      &.green {
        color: $white;
        border-bottom: 1px solid $gray-200;
        background-color: $ocean-green;
      }

      &.red {
        color: $white;
        border-bottom: 1px solid $gray-200;
        background-color: $monza;
      }
    }

    > .summary {
      padding: 10px;

      > .item {
        margin: 5px 0;
        padding: 10px;
        border-left: 8px solid $gray-200;
        box-shadow: 1px 1px 1px $gray-200;
        border-bottom: 1px solid $gray-200;

        &:hover {
          border-left: 8px solid $gray-400;
          border-bottom: 1px solid $gray-400;
        }

        &.blue {
          border-left: 8px solid $dark-periwinkle;
          border-bottom: 1px solid $dark-periwinkle;

          &:hover {
            border-left: 8px solid $forest-blues;
            border-bottom: 1px solid $forest-blues;
          }
        }

        &.yellow {
          border-left: 8px solid $energy-yellow;
          border-bottom: 1px solid $energy-yellow;

          &:hover {
            border-left: 8px solid darken($energy-yellow, 20%);
            border-bottom: 1px solid darken($energy-yellow, 20%);
          }
        }

        &.green {
          border-left: 8px solid $ocean-green;
          border-bottom: 1px solid $ocean-green;

          &:hover {
            border-left: 8px solid darken($ocean-green, 20%);
            border-bottom: 1px solid darken($ocean-green, 20%);
          }

        }

        &.red {
          border-left: 8px solid $monza;
          border-bottom: 1px solid $monza;

          &:hover {
            border-left: 8px solid darken($monza, 20%);
            border-bottom: 1px solid darken($monza, 20%);
          }
        }
      }
    }

    .action-buttons {
      > ul {
        padding: 0;
        margin: 0;
      }

      padding-top: 15px;
      padding-right: 10px;
      padding-bottom: 15px;
      background-color: $gray-100;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
    }

    .children-container {
      display: none;

      .children {
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
