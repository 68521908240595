body {
  font-family: 'Nunito', sans-serif;
}

textarea {
  resize: none;
}

// Formulários

//input {
//  border-radius: 0 !important;
//  border-left-width: 0 !important;
//  border-top-width: 0 !important;
//  border-right-width: 0 !important;
//  border-bottom-width: 2px !important;
//  border-bottom-style: solid !important;
//  border-bottom-color: $gray-300 !important;
//}

//a {
//  color: $dark-periwinkle !important;
//
//  &:hover {
//    color: darken($dark-periwinkle, 15%) !important;
//    text-decoration: none !important;
//  }
//}

//
// SweetAlerts theme.
//
//.swal-overlay {
//  background-color: rgba($brand-color, 0.5);
//}
//
//.swal-modal {
//  border-radius: 0;
//  background-color: $white;
//  border: 3px solid $gray-300;
//}
//
//.swal-title {
//  font-size: 22px;
//}
//
//.swal-text {
//  padding: 4px;
//  text-align: center;
//  background-color: $white;
//}
//
//.swal-footer {
//  margin-top: 32px;
//  overflow: hidden;
//  background-color: $gray-100;
//  border-top: 1px solid $gray-300;
//}
//
//.swal-button {
//  border: none;
//  border-radius: 0;
//  font-weight: bold;
//}
//
//.swal-button--confirm {
//  background-color: darken($download-progress, 1%);
//
//  &.neutral {
//    color: $potters-clay;
//    background-color: $gray-200;
//
//    &:hover {
//      background-color: $gray-300 !important;
//    }
//  }
//
//  &.del {
//    background-color: $sizzling-red;
//
//    &:hover {
//      background-color: $sizzling-red !important;
//    }
//  }
//
//  &:hover {
//    opacity: 0.8;
//    background-color: darken($download-progress, 1%) !important;
//  }
//}
//
//.swal-button--cancel {
//  color: $white;
//  background-color: $sizzling-red;
//
//  &.del {
//    color: $potters-clay;
//    background-color: $gray-300;
//  }
//}
//
//// End SweetAlerts
//
//// Owl Carousel
//
//.owl-theme .owl-dots .owl-dot {
//  outline: none;
//}
//
//.modal {
//  .modal-dialog {
//    .modal-content {
//      .modal-header {
//        color: $white;
//        text-transform: uppercase;
//        background-color: $double-dragon-skin;
//
//        .modal-title {
//          font-weight: bold;
//        }
//
//        i {
//          color: $white;
//        }
//      }
//    }
//  }
//}

